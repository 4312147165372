import {
  GetApplicationDocument,
  GetApplicationQuery,
  GetApplicationQueryVariables,
  GetApplicationsDocument,
  useUpdateApplicationMutation,
} from "@/graphql/types";
import { QueryOptions } from "@apollo/client";
import gql from "graphql-tag";

gql`
  mutation UpdateApplication($input: UpdateApplicationInputType!) {
    updateApplication(input: $input) {
      ...ApplicationDetailsParts
    }
  }
`;

export default function useUpdateApplication() {
  const mutation = useUpdateApplicationMutation({});

  function call(applicationId: string, applicationName: string) {
    mutation.mutate(
      {
        input: {
          applicationId: applicationId,
          name: applicationName,
        },
      },
      {
        refetchQueries: [{ query: GetApplicationsDocument }],
        update: (cache) => {
          const parentQuery: QueryOptions<
            GetApplicationQueryVariables,
            GetApplicationQuery
          > = {
            query: GetApplicationDocument,
            variables: {
              applicationId: applicationId,
            },
          };
          cache.writeQuery({
            ...parentQuery,
            data: {
              application: {
                applicationId: applicationId,
                name: applicationName,
              },
            },
          });
        },
      }
    );
  }
  return {
    ...mutation,
    call,
  };
}
