import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "d-flex align-items-start flex-column-reverse flex-lg-row" }
const _hoisted_2 = { class: "flex-column text-wrap me-3" }
const _hoisted_3 = { class: "mb-0" }
const _hoisted_4 = { class: "d-flex ms-lg-auto mb-4 mb-lg-0 text-nowrap" }
const _hoisted_5 = {
  key: 0,
  class: "me-3 d-none d-lg-block"
}
const _hoisted_6 = { class: "position-relative h-100" }
const _hoisted_7 = { class: "mt-4 d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_pretty_bar = _resolveComponent("pretty-bar")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Button = _resolveComponent("Button")!
  const _component_VDButton = _resolveComponent("VDButton")!

  return (_openBlock(), _createBlock("div", { class: _ctx.classContainerAppHeader }, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_pretty_bar),
        _createVNode("h2", _hoisted_3, [
          _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
          (_ctx.titleButtonIcon)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('onTitleButton'))),
                class: "primary rounded-circle p-2 inverted ms-3 fs-4"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_font_awesome_icon, {
                    icon: ['fas', _ctx.titleButtonIcon]
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _renderSlot(_ctx.$slots, "titleDetails")
      ]),
      _createVNode("div", _hoisted_4, [
        (_ctx.canSearch)
          ? (_openBlock(), _createBlock("div", _hoisted_5, [
              _createVNode("div", _hoisted_6, [
                _createVNode(_component_font_awesome_icon, {
                  style: {"z-index":"1"},
                  class: "\n                position-absolute\n                top-50\n                start-0\n                translate-middle-y\n                ms-3\n                text-muted\n              ",
                  icon: ['fas', 'search']
                }),
                _withDirectives(_createVNode("input", {
                  class: "ps-5 form-control h-100",
                  placeholder: _ctx.searchPlaceholder,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.searchValue = $event)),
                  type: "search"
                }, null, 8, ["placeholder"]), [
                  [_vModelText, _ctx.searchValue]
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.actionButtonTitle)
          ? (_openBlock(), _createBlock(_component_VDButton, {
              key: 1,
              isLoading: _ctx.actionButtonLoading,
              class: "primary",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('onActionButton')))
            }, {
              default: _withCtx(() => [
                _createVNode("strong", null, _toDisplayString(_ctx.actionButtonTitle), 1)
              ]),
              _: 1
            }, 8, ["isLoading"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode("p", _hoisted_7, [
      _renderSlot(_ctx.$slots, "subtitle")
    ])
  ], 2))
}