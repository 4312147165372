
import { computed, defineComponent } from "vue";
import PrettyBar from "@/components/PrettyBar.vue";
import VDButton from "./VDButton.vue";

export default defineComponent({
  components: { PrettyBar, VDButton },
  emits: ["update:searchEntry", "onActionButton", "onTitleButton"],
  props: {
    title: {
      type: String,
      required: true,
    },
    searchPlaceholder: {
      type: String,
      default: "Søg her ...",
    },
    canSearch: {
      type: Boolean,
      default: false,
    },
    actionButtonTitle: {
      type: String,
      default: null,
    },
    actionButtonLoading: {
      type: Boolean,
      default: false,
    },
    titleButtonIcon: {
      type: String,
      default: null,
    },
    searchEntry: {
      type: String,
      required: false,
    },
    classContainerAppHeader: {
      type: String,
      required: false,
      default: "mt-5 mb-5",
    },
  },
  setup(props, { emit }) {
    return {
      searchValue: computed({
        get() {
          return props.searchEntry ?? "";
        },
        set(value: string) {
          emit("update:searchEntry", value);
        },
      }),
    };
  },
});
