
import AppHeader from "@/components/AppHeader.vue";
import Error from "@/components/Error.vue";
import ApplicationModal from "@/components/modals/ApplicationModal/ApplicationModal.vue";
import VDModal from "@/components/modals/VDModal";
import Page from "@/components/Page.vue";
import useError from "@/composables/useError";
import usePluralFilter from "@/composables/usePluralFilter";
import {
  ApplicationType,
  RoleType,
  useGetApplicationQuery,
} from "@/graphql/types";
import { useResult } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { ApplicationTabs } from "@/models/Enum";
import { computed } from "vue";
import VDButton from "@/components/VDButton.vue";
// Query
gql`
  query getApplication($applicationId: ID) {
    application(applicationId: $applicationId) {
      ...ApplicationDetailsParts
      roles {
        description
        roleId
        name
        users {
          count
        }
      }
      profiles {
        profileId
        name
        description
        roles {
          roleId
          name
        }
      }
    }
  }
`;

export default defineComponent({
  inheritAttrs: false,
  name: "Application",
  props: {
    applicationId: {
      type: String,
      required: true,
    },
  },
  components: { AppHeader, Page, Error, ApplicationModal, VDButton },
  setup(props) {
    const expandedRows = ref<ApplicationType[]>([]);
    function onExpandClicked(data: ApplicationType) {
      if (expandedRows.value.length > 0 && expandedRows.value[0] == data) {
        expandedRows.value = [];
      } else {
        expandedRows.value = [data];
      }
    }

    const applicationModal = ref<VDModal<ApplicationType> | null>(null);

    const { result, error, loading } = useGetApplicationQuery(props);
    const router = useRouter();

    function editRole(data: RoleType) {
      router.push({
        name: "role",
        params: {
          id: data.roleId ?? "",
        },
      });
    }

    function editProfile(data: any) {
      router.push({
        name: "profile",
        params: {
          id: data.profileId ?? "",
        },
      });
    }

    const activeTab = ref(ApplicationTabs.Roles);
    const applicationTabs = computed(() => {
      return ApplicationTabs;
    });

    return {
      applicationModal,
      application: useResult(result),
      error: useError(error),
      loading,
      editRole,
      usePluralFilter,
      activeTab,
      applicationTabs,
      editProfile,
      onExpandClicked,
      expandedRows,
    };
  },
});
