import {
  GetApplicationsDocument,
  useDeleteApplicationMutation,
} from "@/graphql/types";
import gql from "graphql-tag";

gql`
  mutation DeleteApplication($applicationID: ID!) {
    deleteApplication(applicationId: $applicationID)
  }
`;

export default function () {
  const mutation = useDeleteApplicationMutation({});

  function call(applicationId: string) {
    mutation.mutate(
      {
        applicationID: applicationId,
      },
      {
        refetchQueries: [{ query: GetApplicationsDocument }],
      }
    );
  }

  return {
    call,
    ...mutation,
  };
}
