
import useConfirmService from "@/composables/useConfirmService";
import useError from "@/composables/useError";
import useToastService from "@/composables/useToastService";
import { ApplicationType } from "@/graphql/types";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { computed, defineComponent, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import VDButton from "../../VDButton.vue";
import useCreateApplication from "./mutations/useCreateApplication";
import useDeleteApplication from "./mutations/useDeleteApplication";
import useUpdateApplication from "./mutations/useUpdateApplication";

export default defineComponent({
  components: { VDButton },
  setup() {
    const toastService = useToastService();
    const confirmService = useConfirmService();
    const router = useRouter();

    const isVisible = ref(false);
    const application: ApplicationType = reactive({});
    const v$ = useVuelidate(useValidations(), application);

    const createMutation = useCreateApplication();
    const updateMutation = useUpdateApplication();
    const deleteMutation = useDeleteApplication();

    function onPrimaryButton() {
      v$.value.$touch();
      if (!v$.value.$error)
        if (application.applicationId) {
          updateMutation.call(
            application.applicationId,
            application.name ?? ""
          );
        } else {
          createMutation.call(application.name ?? "");
        }
    }

    function onDelete(event: Event) {
      if (application.applicationId)
        confirmService.show(
          event,
          `Er du sikker? Handlingen kan ikke fortrydes`,
          "Ja, slet",
          "Afbryd",
          () => {
            deleteMutation.call(application.applicationId ?? "");
          }
        );
    }

    function reset() {
      isVisible.value = false;
      v$.value.$reset();
      Object.assign(application, {});
    }

    createMutation.onDone((result) => {
      toastService.show({
        severity: "success",
        title: "Applikation oprettet",
        message: `Den har fået ID: ${result.data?.createApplication?.applicationId}`,
      });

      reset();
    });

    updateMutation.onDone(() => {
      toastService.show({
        severity: "success",
        title: "Applikationen opdateret",
        message: "Dine ændringer er blevet gemt",
      });

      reset();
    });

    deleteMutation.onDone(() => {
      toastService.show({
        severity: "success",
        title: "Applikation slettet",
        message: "Applikationen er blevet slettet",
      });
      router.push("/applications");
    });

    createMutation.onError((error) =>
      toastService.show({ severity: "error", error: useError(error) })
    );
    updateMutation.onError((error) =>
      toastService.show({ severity: "error", error: useError(error) })
    );

    deleteMutation.onError((error) =>
      toastService.show({ severity: "error", error: useError(error) })
    );

    return {
      isVisible,
      application,
      v$,
      isLoading: computed(
        () => createMutation.loading.value || updateMutation.loading.value
      ),
      show: (value: ApplicationType | null) => {
        Object.assign(application, value);
        isVisible.value = true;
      },
      hide: () => reset(),
      onPrimaryButton,
      onDelete,
      deleteMutation,
    };
  },
});

function useValidations() {
  return {
    name: { required },
  };
}
