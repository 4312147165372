
import { VDError } from "@/models/VDError";
import { defineComponent, PropType } from "vue";
import Spinner from "./Spinner.vue";
import Error from "./Error.vue";

export default defineComponent({
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Object as PropType<VDError>,
      required: false,
    },
  },
  components: {
    Spinner,
    Error,
  },
});
