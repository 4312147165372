import {
  GetApplicationsDocument,
  GetApplicationsQuery,
  useCreateApplicationMutation,
} from "@/graphql/types";
import { QueryOptions } from "@apollo/client";
import gql from "graphql-tag";

gql`
  mutation CreateApplication($input: CreateApplicationInputType!) {
    createApplication(input: $input) {
      ...ApplicationDetailsParts
    }
  }
`;

export default function () {
  const mutation = useCreateApplicationMutation({});

  function call(applicationName: string) {
    mutation.mutate(
      {
        input: {
          name: applicationName,
        },
      },
      {
        update: (cache, { data: newApplication }) => {
          const parentQuery: QueryOptions<undefined, GetApplicationsQuery> = {
            query: GetApplicationsDocument,
          };
          const cachedData = cache.readQuery(parentQuery);
          cache.writeQuery({
            ...parentQuery,
            data: {
              applications: [
                ...(cachedData?.applications ?? []),
                newApplication?.createApplication ?? null,
              ],
            },
          });
        },
      }
    );
  }
  return {
    ...mutation,
    call,
  };
}
