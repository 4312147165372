export default function (
  count: number | undefined | null,
  singularWord: string,
  pluaralWord: string | undefined = undefined
) {
  return count == 1
    ? singularWord
    : pluaralWord
    ? pluaralWord
    : (singularWord += "er");
}
