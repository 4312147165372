import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex py-5 align-items-center justify-content-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_spinner = _resolveComponent("spinner")!
  const _component_error = _resolveComponent("error")!

  return (_ctx.isLoading)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode(_component_spinner)
      ]))
    : (_ctx.error)
      ? (_openBlock(), _createBlock(_component_error, {
          key: 1,
          error: _ctx.error
        }, null, 8, ["error"]))
      : _renderSlot(_ctx.$slots, "default", _mergeProps({ key: 2 }, _ctx.$attrs))
}